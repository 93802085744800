import i18n from '@/i18n'

const Tools: string[] = [
  String(i18n.t('Adobe Photoshop')),
  String(i18n.t('Adobe Illustrator')),
  String(i18n.t('Adobe InDesign')),
  String(i18n.t('Adobe After Effects')),
  String(i18n.t('Adobe Photoshop Lightroom')),
  String(i18n.t('Autodesk 3ds Max')),
  String(i18n.t('Adobe Premiere Pro')),
  String(i18n.t('Adobe XD')),
  String(i18n.t('AutoCAD')),
  String(i18n.t('Sketch App')),
  String(i18n.t('Autodesk Maya (All Versions)')),
  String(i18n.t('Adobe Illustrator CC')),
  String(i18n.t('Blender 3D')),
  String(i18n.t('Wacom Bamboo Tablet')),
  String(i18n.t('Blender')),
  String(i18n.t('Sketch Book')),
  String(i18n.t('Brushes')),
  String(i18n.t('Adobe Bridge')),
  String(i18n.t('Paint Brush')),
  String(i18n.t('Blender Cycles')),
  String(i18n.t('Ballpoint Pen')),
  String(i18n.t('Balsamiq Mockups')),
  String(i18n.t('Maxon Cinema 4D')),
  String(i18n.t('Corona Renderer')),
  String(i18n.t('Adobe Illustrator CC')),
  String(i18n.t('CorelDRAW X5')),
  String(i18n.t('CorelDRAW (unspecified)')),
  String(i18n.t('Canon')),
  String(i18n.t('HTML/CSS')),
  String(i18n.t('Colored Pencils')),
  String(i18n.t('Wacom Cintiq')),
  String(i18n.t('Canva')),
  String(i18n.t('Canvas')),
  String(i18n.t('Adobe Camera Raw')),
  String(i18n.t('Adobe Dreamweaver')),
  String(i18n.t('Adobe Illustrator Draw')),
  String(i18n.t('Affinity Designer')),
  String(i18n.t('Digital Camera')),
  String(i18n.t('Adobe Dimension')),
  String(i18n.t('Corel Draw')),
  String(i18n.t('Nikon D3100')),
  String(i18n.t('Hand Drawing')),
  String(i18n.t('Nikon D5100')),
  String(i18n.t('Nikon D7000')),
  String(i18n.t('Nikon D90')),
  String(i18n.t('Davinci Resolve')),
  String(i18n.t('Adobe After Effects')),
  String(i18n.t('Canon EOS 5D Mark II')),
  String(i18n.t('Canon EOS')),
  String(i18n.t('Canon EOS 5D Mark III')),
  String(i18n.t('Canon EOS 7D')),
  String(i18n.t('Adobe After Effects CC')),
  String(i18n.t('Elementor')),
  String(i18n.t('Canon EOS 60D')),
  String(i18n.t('Adobe Photoshop Elements (All Versions)')),
  String(i18n.t('Adobe Media Encoder')),
  String(i18n.t('Canon Eos 600D')),
  String(i18n.t('Canon EF 50mm F/1.8')),
  String(i18n.t('Figma')),
  String(i18n.t('Adobe Fresco')),
  String(i18n.t('Adobe Flash')),
  String(i18n.t('Final Cut Pro X')),
  String(i18n.t('Adobe Fireworks CS')),
  String(i18n.t('Canon EF 50mm F/1.8')),
  String(i18n.t('Fineliner')),
  String(i18n.t('Facebook')),
  String(i18n.t('Fontlab Studio')),
  String(i18n.t('Fabric')),
  String(i18n.t('Final Cut Pro 7')),
  String(i18n.t('Autodesk Fusion 360')),
  String(i18n.t('Graphite')),
  String(i18n.t('Gouache')),
  String(i18n.t('GIMP')),
  String(i18n.t('Graphic Tablet')),
  String(i18n.t('Adhesive/Glue')),
  String(i18n.t('Grasshopper')),
  String(i18n.t('Glyphs')),
  String(i18n.t('Google Images')),
  String(i18n.t('Glass')),
  String(i18n.t('Google')),
  String(i18n.t('Google Chrome')),
  String(i18n.t('Google Docs')),
  String(i18n.t('HTML/CSS')),
  String(i18n.t('HTML5')),
  String(i18n.t('Hand Drawing')),
  String(i18n.t('HTML')),
  String(i18n.t('Houdini')),
  String(i18n.t('Handmade')),
  String(i18n.t('Huion Tablet')),
  String(i18n.t('Toon Boom Harmony')),
  String(i18n.t('SideFX Houdini')),
  String(i18n.t('Huion Graphic Tablet')),
  String(i18n.t('Hand- Delete')),
  String(i18n.t('Adobe Illustrator')),
  String(i18n.t('Adobe InDesign')),
  String(i18n.t('Wacom Intuos')),
  String(i18n.t('Ink')),
  String(i18n.t('Adobe Illustrator CC')),
  String(i18n.t('Adobe Ilustrator')),
  String(i18n.t('Apple IMac')),
  String(i18n.t('IPad Pro')),
  String(i18n.t('Adobe Illustrator Draw')),
  String(i18n.t('Canon EOS 5D Mark II')),
  String(i18n.t('Canon EOS 5D Mark III')),
  String(i18n.t('Inkscape')),
  String(i18n.t('JavaScript')),
  String(i18n.t('Sketchbooks, Notebooks, Journals')),
  String(i18n.t('Moleskine Journal')),
  String(i18n.t('Jigsaw')),
  String(i18n.t('Angular JS')),
  String(i18n.t('Justinmind')),
  String(i18n.t('Keyshot')),
  String(i18n.t('Krita')),
  String(i18n.t('Apple Keynote')),
  String(i18n.t('Luxion Keyshot')),
  String(i18n.t('Kraft Paper')),
  String(i18n.t('X-Acto Knife')),
  String(i18n.t('Unreal Development Kit')),
  String(i18n.t('Palette Knife')),
  String(i18n.t('Kodak Portra')),
  String(i18n.t('Kerkythea')),
  String(i18n.t('Pentax K-1')),
  String(i18n.t('Pentax K1000')),
  String(i18n.t('Adobe Photoshop Lightroom')),
  String(i18n.t('Lumion')),
  String(i18n.t('Adobe Lightroom')),
  String(i18n.t('Adobe Lightroom CC')),
  String(i18n.t('Laser Cutter')),
  String(i18n.t('Adobe Lightroom Mobile')),
  String(i18n.t('Adobe Lightroom Classic')),
  String(i18n.t('Luxology Modo')),
  String(i18n.t('Luxion Keyshot')),
  String(i18n.t('LED Lights')),
  String(i18n.t('Laptop')),
  String(i18n.t('Autodesk 3ds Max')),
  String(i18n.t('Maxon Cinema 4D')),
  String(i18n.t('Autodesk Maya (All Versions)')),
  String(i18n.t('Apple MacBook Pro')),
  String(i18n.t('Microsoft PowerPoint')),
  String(i18n.t('Canon EOS 5D Mark II')),
  String(i18n.t('Microsoft Word')),
  String(i18n.t('Marker')),
  String(i18n.t('Canon EOS 5D Mark III')),
  String(i18n.t('Autodesk Maya')),
  String(i18n.t('Copic Markers')),
  String(i18n.t('Nikon')),
  String(i18n.t('Vray Next')),
  String(i18n.t('Nikon Camera')),
  String(i18n.t('Nikon D3100')),
  String(i18n.t('Nikon D5100')),
  String(i18n.t('Nikon D7000')),
  String(i18n.t('Nikon D90')),
  String(i18n.t('Notebooks')),
  String(i18n.t('Nuke')),
  String(i18n.t('Nikon D750')),
  String(i18n.t('Notepad++')),
  String(i18n.t('Nikon Nikkor 50mm 1.8')),
  String(i18n.t('Capture One Pro')),
  String(i18n.t('Oils')),
  String(i18n.t('Octane Render')),
  String(i18n.t('Mac OS X')),
  String(i18n.t('Oil Paint')),
  String(i18n.t('Capture One')),
  String(i18n.t('Oil On Canvas')),
  String(i18n.t('Acrylic On Canvas')),
  String(i18n.t('Microsoft Office')),
  String(i18n.t('Octane')),
  String(i18n.t('Phase One Camera')),
  String(i18n.t('Adobe Photoshop')),
  String(i18n.t('Adobe Photoshop Lightroom')),
  String(i18n.t('Adobe Premiere Pro')),
  String(i18n.t('Procreate')),
  String(i18n.t('Pencil')),
  String(i18n.t('Paper')),
  String(i18n.t('Apple MacBook Pro')),
  String(i18n.t('Acrylic Paint')),
  String(i18n.t('Colored Pencils')),
  String(i18n.t('Pixologic Zbrush')),
  String(i18n.t('Microsoft PowerPoint')),
  String(i18n.t('Pen')),
  String(i18n.t('Quark Xpress 8')),
  String(i18n.t('Quixel Megascans')),
  String(i18n.t('Quark Xpress 7')),
  String(i18n.t('Quicktime Pro')),
  String(i18n.t('Qgis')),
  String(i18n.t('Quark Xpress 6')),
  String(i18n.t('Quark Xpress 4')),
  String(i18n.t('Quark Xpress 5')),
  String(i18n.t('Quixel Suite')),
  String(i18n.t('Quixel Mixer')),
  String(i18n.t('Quixel')),
  String(i18n.t('Apple Quick Time Pro')),
  String(i18n.t('Corona Renderer')),
  String(i18n.t('Rhinoceros 5.0')),
  String(i18n.t('V-Ray')),
  String(i18n.t('Autodesk Revit Architecture (All Versions)')),
  String(i18n.t('Adobe Camera Raw')),
  String(i18n.t('Rhinoceros')),
  String(i18n.t('Rhino 3D')),
  String(i18n.t('Octane Render')),
  String(i18n.t('Canon EOS Rebel T3i')),
  String(i18n.t('Autodesk Revit')),
  String(i18n.t('Davinci Resolve')),
  String(i18n.t('Vray Render')),
  String(i18n.t('SketchUp')),
  String(i18n.t('Sketch App')),
  String(i18n.t('SolidWorks')),
  String(i18n.t('Paint Tool SAI')),
  String(i18n.t('Sketchbook Pro')),
  String(i18n.t('Substance 3D Painter')),
  String(i18n.t('Sketch')),
  String(i18n.t('Clip Studio Paint')),
  String(i18n.t('Sketch Book')),
  String(i18n.t('Autodesk SketchBook')),
  String(i18n.t('Painttool Sai')),
  String(i18n.t('Spray Paint')),
  String(i18n.t('Wacom Tablet')),
  String(i18n.t('Wacom Bamboo Tablet')),
  String(i18n.t('Paint Tool SAI')),
  String(i18n.t('Sublime Text 2')),
  String(i18n.t('Canon EOS Rebel T3i')),
  String(i18n.t('Graphic Tablet')),
  String(i18n.t('Tilda')),
  String(i18n.t('Twinmotion')),
  String(i18n.t('Marmoset Toolbag')),
  String(i18n.t('Felt-tip Pen/Marker')),
  String(i18n.t('Toon Boom Studio')),
  String(i18n.t('Avid Pro Tools')),
  String(i18n.t('CorelDRAW (unspecified)')),
  String(i18n.t('Unity 3D')),
  String(i18n.t('Adobe Creative Suite (unspecified)')),
  String(i18n.t('Sony Vegas Pro (Unspecified)')),
  String(i18n.t('Unreal Engine 4')),
  String(i18n.t('Software Used: Autodesk 3ds Max + Vray Next - Adobe Photoshop')),
  String(i18n.t('Canon EF 24-105mm F/4L IS USM')),
  String(i18n.t('Fujifilm Equipment (unspecified)')),
  String(i18n.t('Apple Mac (unspecified)')),
  String(i18n.t('Unreal Development Kit')),
  String(i18n.t('Canon EF 24-70mm F/2.8 L USM')),
  String(i18n.t('Unsplash')),
  String(i18n.t('Vray')),
  String(i18n.t('Autodesk Maya (All Versions)')),
  String(i18n.t('V-Ray')),
  String(i18n.t('Autodesk Revit Architecture (All Versions)')),
  String(i18n.t('Vray Next')),
  String(i18n.t('Autodesk SketchBook (All Versions)')),
  String(i18n.t('Adobe Photoshop Elements (All Versions)')),
  String(i18n.t('Corel Painter (All Versions)')),
  String(i18n.t('Vray Render')),
  String(i18n.t('Autodesk Alias Design (All Versions)')),
  String(i18n.t('3dmax, Vray, Photoshop')),
  String(i18n.t('Sony Vegas Pro (Unspecified)')),
  String(i18n.t('Wacom Intuos')),
  String(i18n.t('WordPress')),
  String(i18n.t('Watercolor')),
  String(i18n.t('Wacom Tablet')),
  String(i18n.t('Wacom Bamboo Tablet')),
  String(i18n.t('Wacom Cintiq')),
  String(i18n.t('Watercolor Paper')),
  String(i18n.t('Microsoft Word')),
  String(i18n.t('Wood')),
  String(i18n.t('Watercolor Pencils')),
  String(i18n.t('Adobe XD')),
  String(i18n.t('CorelDRAW X5')),
  String(i18n.t('CorelDRAW X7')),
  String(i18n.t('Final Cut Pro X')),
  String(i18n.t('CorelDRAW X8')),
  String(i18n.t('CorelDRAW X4')),
  String(i18n.t('Mac OS X')),
  String(i18n.t('CorelDRAW X3')),
  String(i18n.t('Ibis Paint X')),
  String(i18n.t('Corel Draw X7')),
  String(i18n.t('CorelDRAW X6')),
  String(i18n.t('X-Acto Knife')),
  String(i18n.t('Lápiz Y Papel')),
  String(i18n.t('Yashica FX-3')),
  String(i18n.t('Yashica MAT 124 G')),
  String(i18n.t('Yashica Camera')),
  String(i18n.t('Yeti')),
  String(i18n.t('Yashica FX-3 Super 2000')),
  String(i18n.t('Yashica T4')),
  String(i18n.t('Yashica MAT 124')),
  String(i18n.t('Yafaray')),
  String(i18n.t('Yashica FX-D')),
  String(i18n.t('Pixologic Zbrush')),
  String(i18n.t('Zbrush')),
  String(i18n.t('Zeplin')),
  String(i18n.t('Nikon Z6')),
  String(i18n.t('Zoom H4N')),
  String(i18n.t('Zenit Camera')),
  String(i18n.t('Form-Z')),
  String(i18n.t('Nikon Z7')),
  String(i18n.t('Zeplin')),
  String(i18n.t('Z-Brush')),
  String(i18n.t('Zapier Integration')),
  String(i18n.t('Zinc Plate')),
]

export default Tools
