export default class SelectOption {
  key: any = null!
  title: string = null!
  $isDisabled: boolean = null!

  setKey(key: any): this {
    this.key = key

    return this
  }

  setTitle(title: string): this {
    this.title = title

    return this
  }

  setDisabled(disabled: boolean): this {
    this.$isDisabled = disabled

    return this
  }
}
